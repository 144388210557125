module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"stylesProvider":{"injectFirst":true,"pathToStylesProvider":"C:\\code\\gatsby\\estetiese-bremen.de/src/components/stylesProviderProps.ts"},"pathToEmotionCacheProps":""},
    },{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://estetiese.de","canonical":"https://estetiese.de","site_name":"Privatpraxis ESTETIESE für ganzheitliche Ästhetische Medizin, Anti-Aging und Medizinische Kosmetik","title":"ESTETIESE","titleTemplate":"ESTETIESE| %s","description":"ESTETIESE: Privatpraxis für ganzheitliche Ästhetische Medizin, Anti-Aging und Medizinische Kosmetik unter der Leitung von Dr. med. Anna Maria Müller.","language":"de","openGraph":{"type":"website","site_name":"Privatpraxis ESTETIESE für ganzheitliche Ästhetische Medizin, Anti-Aging und Medizinische Kosmetik","locale":"de_DE","url":"https://estetiese.de","title":"ESTETIESE","description":"ESTETIESE: Privatpraxis für ganzheitliche Ästhetische Medizin, Anti-Aging und Medizinische Kosmetik unter der Leitung von Dr. med. Anna Maria Müller.","images":[{"url":"https://estetiese.de/default.jpg","width":800,"height":600,"alt":"Og Image Alt"}]},"handle":"@EstetieseBremen","site":"@EstetieseBremen","cardType":"summary_large_image"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Privatpraxis ESTETIESE Dr. med. Anna Maria Müller","short_name":"ESTETIESE","description":"ESTETIESE: Privatpraxis für ganzheitliche Ästhetische Medizin, Anti-Aging und Medizinische Kosmetik unter der Leitung von Dr. med. Anna Maria Müller.","start_url":"/","lang":"de","display":"standalone","icon":"static/favicons/favicon.png","background_color":"#666","theme_color":"#fff","icons":[{"src":"static/favicons/favicon_512px.png","sizes":"512x512","type":"image/png","purpose":"any"},{"src":"static/favicons/favicon_256px.png","sizes":"256x256","type":"image/png","purpose":"any"},{"src":"static/favicons/favicon_192px.png","sizes":"192x192","type":"image/png","purpose":"any"},{"src":"static/favicons/favicon_127px.png","sizes":"127x127","type":"image/png","purpose":"any"},{"src":"static/favicons/favicon_96px.png","sizes":"96x96","type":"image/png","purpose":"any"},{"src":"static/favicons/favicon_48px.png","sizes":"48x48","type":"image/png","purpose":"any"},{"src":"static/favicons/favicon_192_mascable.png","sizes":"192x192","type":"image/png","purpose":"maskable"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"8f7cd4e9d43c7913257436ae10c0b086"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
