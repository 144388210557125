exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aesthetische-medizin-js": () => import("./../../../src/pages/AesthetischeMedizin.js" /* webpackChunkName: "component---src-pages-aesthetische-medizin-js" */),
  "component---src-pages-anti-aging-js": () => import("./../../../src/pages/AntiAging.js" /* webpackChunkName: "component---src-pages-anti-aging-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-dermatologie-js": () => import("./../../../src/pages/Dermatologie.js" /* webpackChunkName: "component---src-pages-dermatologie-js" */),
  "component---src-pages-expertise-js": () => import("./../../../src/pages/expertise.js" /* webpackChunkName: "component---src-pages-expertise-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-leistungen-js": () => import("./../../../src/pages/Leistungen.js" /* webpackChunkName: "component---src-pages-leistungen-js" */),
  "component---src-pages-medizinische-kosmetik-js": () => import("./../../../src/pages/MedizinischeKosmetik.js" /* webpackChunkName: "component---src-pages-medizinische-kosmetik-js" */),
  "component---src-pages-unserepraxis-js": () => import("./../../../src/pages/unserepraxis.js" /* webpackChunkName: "component---src-pages-unserepraxis-js" */)
}

